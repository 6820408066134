import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SMobileImageWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: -4%;
    width: 120%;

   @media (max-width: 768px), (orientation: portrait) {
        width: 70%;
        left: 15%;
    }
`;

const SImage = styled(GatsbyImage)`
    width: 90%;
    margin: 0 auto;
`;

export const MobileImageWrapper = ({ image, projectName }) => {
    return (
        <SMobileImageWrapper>
            <SImage image={getImage(image)} alt={projectName} />
        </SMobileImageWrapper>
    );
};
