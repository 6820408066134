import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SDesktopImageWrapper = styled.div`
    position: absolute;
    bottom: 5%;
    left: 15%;
    width: 70%;
    display: flex;
    justify-content: center;

    @media (max-width: 768px), (orientation: portrait) {
        bottom: 10%;
    }
`;

const SImage = styled(GatsbyImage)`
    width: 90%;
    margin: 0 auto;
`;

export const DesktopImageWrapper = ({ image, projectName }) => {
    return (
        <SDesktopImageWrapper>
            <SImage image={getImage(image)} alt={projectName} />
        </SDesktopImageWrapper>
    );
};
