import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SGrid = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const SMain = styled.div`
    margin-bottom: 0.8rem;
    display: flex;
    flex: 2;
    align-items: center;
    height: 70%;
    background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};

    @media (max-width: 768px), (orientation: portrait) {
        align-items: flex-start;
        min-height: 70vw;
    }
`;

const SLower = styled.div`
    display: flex;
    flex: 1;

    @media (max-width: 768px), (orientation: portrait) {
        flex-direction: column;
    }
`;

const SLeft = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
    margin-right: 0.8rem;
    width: 63%;

    @media (max-width: 768px), (orientation: portrait) {
        width: 100%;
        min-height: 40vw;
        margin-bottom: 0.8rem;
        margin-right: unset;
        ${({ changeOrder }) =>
            changeOrder &&
            `
                order: 2;
                margin-bottom: 0;
                z-index: 2;
        `}
    }
`;

const SRight = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};

    @media (max-width: 768px), (orientation: portrait) {
        flex: 1;
        min-height: 40vw;
        ${({ changeOrder }) =>
            changeOrder &&
            `
        order: 1;
        margin-bottom: 0.8rem;
        `}
    }
`;

const SImage = styled(GatsbyImage)`
    height: 100%;
    @media (max-width: 768px), (orientation: portrait) {
        min-height: 65vw;
    }
`;

export const DesktopMobileGrid = ({ leftImage, rightImage, main, bgColor, changeOrder, projectName }) => {
    return (
        <SGrid>
            <SMain bgColor={bgColor}>
                <SImage image={getImage(main)} alt={projectName} />
            </SMain>
            <SLower>
                <SLeft bgColor={bgColor} changeOrder={changeOrder}>
                    {leftImage}
                </SLeft>
                <SRight bgColor={bgColor} changeOrder={changeOrder}>
                    {rightImage}
                </SRight>
            </SLower>
        </SGrid>
    );
};
