import React from 'react';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { graphql, useStaticQuery } from 'gatsby';
import { DesktopMobileGrid } from 'components/DesktopMobileGrid';
import { DesktopImageWrapper } from 'components/DesktopImageWrapper';
import { MobileImageWrapper } from 'components/MobileImageWrapper';

export const KurowskiPageView = () => {
    const images = useStaticQuery(graphql`
        query KurowskiPageImages {
            main: file(name: { eq: "kancelaria-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            mobile: file(name: { eq: "kancelaria-mobile2" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
            desktop: file(name: { eq: "kancelaria-desktop2" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE)
                }
            }
        }
    `);

    const { main, desktop, mobile } = images;

    return (
        <ProjectMainLayout projectName={PROJECTS.kurowskiPage.name}>
            <DesktopMobileGrid
                main={main}
                bgColor="kancelaria_bg"
                projectName={PROJECTS.kurowskiPage.name}
                leftImage={<DesktopImageWrapper image={desktop} />}
                rightImage={<MobileImageWrapper image={mobile} />}
            />
        </ProjectMainLayout>
    );
};
